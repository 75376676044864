/* eslint-disable */

import React from 'react';
import { Twitter, Instagram, Facebook, Youtube, Linkedin } from 'lucide-react';
import aboutUsImage from './assets/aboutus.jpeg';
import copywritingImage from './assets/copywriting.png';
import experienceImage from './assets/experience.png';
import marketingImage from './assets/marketing.png';
import programmingImage from './assets/programming.jpg';

const App = () => {
  return (
    <div className="w-full overflow-x-hidden">
      <header className="flex justify-center items-center px-4 sm:px-8 py-6 bg-white text-black w-full">
        <h1 onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })} 
            className="text-3xl sm:text-4xl font-bold p-2 border border-black cursor-pointer hover:bg-black hover:text-white transition-colors">
          PixelWrite © 
        </h1>
      </header>

      <section className="flex flex-col lg:flex-row items-center px-4 sm:px-8 py-12 sm:py-20 bg-black text-white w-full">
        <div className="w-full lg:w-1/2 lg:pr-12 mb-8 lg:mb-0">
          <h2 className="text-4xl sm:text-5xl font-bold mb-6">PixelWrite</h2>
          <p className="text-lg leading-relaxed">
            At Pixel Write, we specialise in Web Development and Digital Literacy. Through empowering startups by kickstarting their digital
            presence and enhancing well-developed businesses, our team makes your goals, our promise. Here at Pixel Write, we craft impactful
            SEO strategies and engage in relevant marketing channels to deliver compelling copywriting and management over your social media tools,
            offering a full suite of services tailored to your growth.
          </p>
        </div>
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
          <img 
            src={aboutUsImage} 
            alt="PixelWrite Team" 
            className="w-2/3 rounded-lg shadow-lg ml-8"
          />
        </div>
      </section>

      <section className="bg-white text-black px-4 sm:px-8 py-12 sm:py-20 w-full">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12">Experience</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left">
              <h3 className="text-2xl font-bold mb-4">Industry Expertise</h3>
              <p className="text-lg leading-relaxed text-left">
                Our team brings over two decades of combined experience in digital marketing, 
                content creation, and strategic communications. We've successfully served 
                clients across technology, healthcare, finance, and retail sectors.
              </p>
            </div>
            <div className="flex justify-center md:justify-end">
              <img 
                src={experienceImage} 
                alt="Team Experience" 
                className="w-1/2 rounded-lg shadow-lg md:ml-20"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center p-6 border border-black rounded-lg">
              <h4 className="text-xl font-bold mb-2">10+</h4>
              <p>Years Combined Experience</p>
            </div>
            <div className="text-center p-6 border border-black rounded-lg">
              <h4 className="text-xl font-bold mb-2">100+</h4>
              <p>Projects Completed</p>
            </div>
            <div className="text-center p-6 border border-black rounded-lg">
              <h4 className="text-xl font-bold mb-2">100%</h4>
              <p>Client Satisfaction</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-black text-white px-4 sm:px-8 py-12 sm:py-20 w-full">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-16">Services</h2>
        <div className="flex flex-col lg:flex-row justify-between gap-8">
          <div className="w-full lg:w-1/3 text-center px-4 sm:px-6">
            <h3 className="text-2xl font-bold mb-4">Marketing</h3>
            <img 
              src={marketingImage} 
              alt="Marketing Services" 
              className="w-full rounded-lg shadow-lg mb-4 h-64 object-cover"
            />
            <p className="leading-relaxed text-left">
              Pixel Write offers comprehensive marketing services designed to amplify your brand's presence and engagement across all digital platforms. Our team of experts specialise in crafting tailored strategies that align with your unique business goals, ensuring measurable results and long-term success.
            </p>
          </div>
          <div className="w-full lg:w-1/3 text-center px-4 sm:px-6 lg:border-x border-white py-8 lg:py-0">
            <h3 className="text-2xl font-bold mb-4">Copywriting</h3>
            <img 
              src={copywritingImage} 
              alt="Copywriting Services" 
              className="w-full rounded-lg shadow-lg mb-4 h-64 object-cover"
            />
            <p className="leading-relaxed text-left">
              The correct length, word choice, and placement is detrimental to your viewer's eyes. Our team's delivery of exceptional copywriting services is backed by experts who have continually crafted content to convey your brand's voice and drive performance with measurable results.
            </p>
          </div>
          <div className="w-full lg:w-1/3 text-center px-4 sm:px-6">
            <h3 className="text-2xl font-bold mb-4">Custom Integration</h3>
            <img 
              src={programmingImage} 
              alt="Custom Integration Services" 
              className="w-full rounded-lg shadow-lg mb-4 h-64 object-cover"
            />
            <p className="leading-relaxed text-left">
              In this world of web space, it is detrimental that you not only stay afloat with digital inputs, but very well swim. Through custom integration services we offer, including CMS, Social Media Integration, API development and content syndication, Pixel Write ensures that you are user friendly with a seamless integration across the board.
            </p>
          </div>
        </div>
      </section>

      <section id="contact" className="px-4 sm:px-8 py-12 sm:py-20 bg-white w-full">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12">Contact Us</h2>
        <div className="max-w-2xl mx-auto">
          <form className="space-y-6">
            <div>
              <label htmlFor="name" className="block mb-2 font-medium">Name</label>
              <input 
                type="text" 
                id="name" 
                className="w-full p-3 border border-black rounded"
                placeholder="Your name"
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 font-medium">Email</label>
              <input 
                type="email" 
                id="email" 
                className="w-full p-3 border border-black rounded"
                placeholder="your@email.com"
              />
            </div>
            <div>
              <label htmlFor="message" className="block mb-2 font-medium">Message</label>
              <textarea 
                id="message" 
                rows={4} 
                className="w-full p-3 border border-black rounded"
                placeholder="How can we help you?"
              />
            </div>
            <button 
              type="submit" 
              className="w-full bg-black text-white py-3 px-6 rounded hover:bg-gray-800 transition-colors"
            >
              Send Message
            </button>
          </form>
        </div>
      </section>

      <footer className="bg-black text-white px-4 sm:px-8 py-12 w-full min-w-full">
        <div className="max-w-6xl mx-auto w-full">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-2xl font-bold mb-8">Connect With Us</h2>
            <div className="flex space-x-6 mb-8">
              <a href="#" className="hover:scale-110 transition-transform">
                <Twitter size={32} color="white" />
              </a>
              <a href="#" className="hover:scale-110 transition-transform">
                <Instagram size={32} color="white" />
              </a>
              <a href="#" className="hover:scale-110 transition-transform">
                <Facebook size={32} color="white" />
              </a>
              <a href="#" className="hover:scale-110 transition-transform">
                <Youtube size={32} color="white" />
              </a>
              <a href="#" className="hover:scale-110 transition-transform">
                <Linkedin size={32} color="white" />
              </a>
            </div>
            <p className="text-center text-sm">
              © 2025 PixelWrite. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;